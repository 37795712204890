













































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question526',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
      options: [
        {text: 'a', value: 'a'},
        {text: 'b', value: 'b'},
        {text: 'c', value: 'c'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q526_main.png';
    },
    imageAName() {
      return '/img/assignments/q526_choiceA.png';
    },
    imageBName() {
      return '/img/assignments/q526_choiceB.png';
    },
    imageCName() {
      return '/img/assignments/q526_choiceC.png';
    },
  },
};
